<template>
  <div id="page-positions-list">
    <KCrudLayout :search.sync="searchQuery">
      <template #header>
        {{ $tc('position.title', 2) }}
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          depressed
          tile
          @click="createDialog = true"
        >
          {{ $t('actions.createResource', { resource: $tc('position.title', 1) }) }}
        </v-btn>
        <PositionForm
          v-model="createDialog"
          :request="createRequest"
          :title="$tc('position.title',1)"
          :values="createFormValues"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <KCrudTable
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          :search="searchQuery"
          language-prefix="position.fields"
          resource="position"
          @click:edit="openUpdate"
        />
      </template>
    </KCrudLayout>
    <PositionForm
      v-model="updateDialog"
      :request="updateRequest"
      :title="$tc('position.title',1)"
      :values="updateFormValues"
      is-update-form
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import Position from '@/application/models/Position.js';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update } from '@/modules/position/api/position.js';
import PositionForm from '@/modules/position/components/Form.vue';

export default {
  name: 'PositionIndex',
  components: {
    KCrudTable,
    PositionForm,
    KCrudLayout,
  },
  data() {
    return {
      searchQuery: '',
      updateDialog: false,
      createDialog: false,
      updateFormValues: new Position(),
      createFormValues: new Position(),
    };
  },
  computed: {
    crudHeaders() {
      return [
        {
          value: 'name',
          language: 'name',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'settings.positions' },
        text: this.$tc('position.title', 2),
      },
    ]);
  },
  methods: {
    updateRequest() {
      return update(this.updateFormValues);
    },
    createRequest() {
      return create(this.createFormValues);
    },
    indexRequest() {
      return index(...arguments);
    },
    async openUpdate(item) {
      this.updateFormValues = new Position();
      const positionShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(positionShowRequest.data.data);
      this.updateDialog = true;
    },
  },
};
</script>

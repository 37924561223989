import Model from '@/application/models/model.js';

class Position extends Model {
  id = 0;
  name = '';
  sector = null;
  description = '';

  mapForRequest() {
    return {
      name: this.name,
      sectorId: this.sector.id,
      description: this.description,
    };
  }
}

export default Position;
